.container {
    display: flex !important;
}

.home-page {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    .text-zone {
        //left: 27%;
        max-height: 90%;
        position: relative;
        text-align: justify;
        display: flex;
        justify-content: center;
        align-items: center;
        /* top: 28%; */
        /* transform: translateY(-50%); */
        width: 90%;
        margin: 0 auto;
        flex-direction: column;
    }
    
    .logo-container {
        border-radius: 5px;
        position: relative;
        width: 40%;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center !important;
        z-index: -1;
        margin: 0 auto;
        padding: 20px;
    }

    // .hanalei__photo {
    //     width: 50%;
    //     height: 100%;
    //     top: 0;
    //     left: 20%;
    //     bottom: 0;
    //     padding-top: 18%;
    //     margin-left: 0;
    //     position: absolute;
    //     right: 0;
    //     overflow: hidden;
    //     margin-top: 200px;
    // }

    h1 {
        color: #fff;
        font-size: 72px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;

        // &::before {
        //     content: '<h1>';
        //     font-family: 'La Belle Aurore';
        //     color: #ffd700;
        //     font-size: 18px;
        //     position: absolute;
        //     margin-top: -40px;
        //     left: 15px;
        //     opacity: 0.6;
        // }

        // &::after {
        //     content: '</h1>';
        //     font-family: 'La Belle Aurore';
        //     color: #ffd700;
        //     font-size: 18px;
        //     position: absolute;
        //     margin-top: 18px;
        //     margin-left: 20px;
        //     animation: fadeIn 1s 1.7s backwards;
        //     opacity: 0.6;
        // }

        img {
            width: 32px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
        padding-left: 10%;
    }

    .btn-container {
        display: flex;
        padding-right: 40px;
    }

    .flat-button {
        text-align: center;
        justify-content: center;
        min-width: 200px;
        margin: 10px;
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            transition: 0.3s;
            background: #ffd700;
            color: #333;
        }
    }
}
@media screen and (max-width: 1214px) and (min-width: 1201px){
    .text-zone {
        padding-top: 80px;
      //  margin-top: 20px;
    }
}

@media screen and (max-width: 1214px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    // .hanalei__photo {
    //     position: initial;
    //     width: 100%;
    //     height: 0%;
    //     overflow: visible;
    // }

    .home-page {
        .text-zone {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
        h1 {
            font-size: 54px;
            text-align: left;
        }
        h2 {
            color: #8d8d8d;
            margin-top: 20px;
            font-weight: 400;
            font-size: 11px;
            font-family: sans-serif;
            letter-spacing: 3px;
            animation: fadeIn 1s 1.8s backwards;
            padding-left: 15%;
        }

        .btn-container {
            flex-direction: column;
            padding: 0;
        }

        .flat-button {
            margin: 5px;
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }

        .logo-container {
            align-items: center;
            height: auto;
            position: relative;
            top: 50px;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 43%;
            padding: 0;
            
            svg {
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: 0;
                margin: auto;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .home-page {
        h1 {
            font-size: 40px;
        }
        h2 {
            text-align: left;
            
        }
        .logo-container {
            width: 65%;
        }
    }
}

@media screen and (max-width: 576px) {
    .home-page {
        h1 {
            font-size:33px;
        }
        h2 {
            text-align: left;
            padding-left: 11%;
        }
        .logo-container {
            width: 80%;
            margin-bottom: 30px;
            top: 30px;
        }
    }
}